import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import * as Icons from "components/Icons"
import styles from "./XButton.module.css"

export const XButton = ({ className, to, ...props }) =>
  to ? (
    <Link {...props} to={to} className={cx(styles.root, className)}>
      {Icons.X}
    </Link>
  ) : (
    <button {...props} type="button" className={cx(styles.root, className)}>
      {Icons.X}
    </button>
  )

export default XButton
