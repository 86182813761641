import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "components/Layout"
import Container from "components/Container"
import XButton from "components/XButton"
import styles from "./Career.module.css"

// eslint-disable-next-line
export const CareerPage = ({ data, headerImage, html, ...props }) => (
  <Layout {...data} {...props} isMobileHeaderHidden isMobileFooterHidden>
    <div className={styles.xwrap}>
      <XButton to="/careers" className={styles.x} />
    </div>
    <div className={styles.image}>
      {headerImage && headerImage.extension === "svg" ? (
        <img alt={data.title} title={data.title} src={headerImage.publicURL} loading="lazy" width="480" height="480"/>
      ) : headerImage && headerImage.childImageSharp ? (
        <Img
          loading="lazy"
          objectFit="cover"
          alt={data.title}
          title={data.title}
          fluid={headerImage.childImageSharp.fluid}
        />
      ) : null}
      <Container tag="h1" className={styles.title}>
        Careers
      </Container>
    </div>
    <div className={styles.body}>
      <Container className={styles.wrap}>
        <h2>{data.title}</h2>
        <h3>{data.kind}</h3>
        {data.location ? <h3>{data.location}</h3> : null}
        <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />
        {data.link ? (
          <a href={data.link} rel="noopener noreferrer" target="_blank">
            Apply now
          </a>
        ) : null}
      </Container>
    </div>
  </Layout>
)

// eslint-disable-next-line
export const CareersPageWrapper = (props) => (
  <CareerPage
    {...props}
    headerImage={props.data.headerImage}
    html={props.data.markdownRemark.html}
    data={props.data.markdownRemark.frontmatter}
  />
)

export default CareersPageWrapper

export const pageQuery = graphql`
  query CareerPage($id: String) {
    headerImage: file(relativePath: { eq: "Career-Preview.svg" }) {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 3643) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fixed(width: 1200, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        kind
        link
        location
      }
    }
  }
`
